@use '../theme' as *
@use 'sass:color'
    
// @import "../../node_modules/bulma/bulma"

// Importing specific Bulma items
@import "../../node_modules/bulma/sass/utilities/_all"
@import "../../node_modules/bulma/sass/base/_all"
@import "../../node_modules/bulma/sass/elements/_all"
@import "../../node_modules/bulma/sass/form/_all"
// @import "../../node_modules/bulma/sass/components/_all"
@import "../../node_modules/bulma/sass/components/breadcrumb"
@import "../../node_modules/bulma/sass/components/card"
@import "../../node_modules/bulma/sass/components/dropdown"
@import "../../node_modules/bulma/sass/components/level"
@import "../../node_modules/bulma/sass/components/media"
// @import "../../node_modules/bulma/sass/components/menu"
@import "../../node_modules/bulma/sass/components/message"
@import "../../node_modules/bulma/sass/components/modal"
@import "../../node_modules/bulma/sass/components/navbar"
@import "../../node_modules/bulma/sass/components/pagination"
@import "../../node_modules/bulma/sass/components/panel"
@import "../../node_modules/bulma/sass/components/tabs"

@import "../../node_modules/bulma/sass/grid/_all"
@import "../../node_modules/bulma/sass/helpers/_all"
@import "../../node_modules/bulma/sass/layout/_all"

@import 'bulma-tooltip'

@import './datepicker/datepicker.scss'

body
    background: white
    
.button
    border-left-width: 1px !important
    border-right-width: 1px !important
    border-top-width: 1px !important
    border-bottom-width: 4px !important
    border-style: solid !important
    border-radius: 8px
    height: fit-content !important

    &.is-primary
        border-color: $primaryDarker !important

    &.is-danger
        border-color: $dangerDarker !important

    &.is-warning
        border-color: $warningDarker !important

    &.is-info
        border-color: $infoDarker !important

    &.is-light
        border-color: #dadee3 !important

    &.is-link
        border-color: color.scale($link, $lightness: -10%) !important

        &:not(:hover)
            background: white !important

        &:active,
        &:focus
            color: $link !important
            background: white !important

    &.is-outlined:not(:focus):not(:active):not(:hover):not(.is-white)
        background: white !important

    &:active
        border-bottom-width: 1px !important
        transform: translateY(3px)

.icon
    svg
        width: 100%

.field.has-addons .button:active
    transform: translateY(0px)!important

.field.has-addons .button
    height: 2.5em!important

.button.pad-bottom:active
    margin-bottom: 3px!important

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button 
    -webkit-appearance: none
    margin: 0
    -moz-appearance: textfield

.modal-card-foot.buttons
    margin-bottom:0!important
        
.has-text-weight-black
    font-weight: 900

.title.is-size-0
    font-size: 3.5rem!important

.tabs
    cursor: pointer

    li
        cursor: pointer

    .tab
        align-items: center
        border-bottom-color: transparent
        border-bottom-style: solid
        border-bottom-width: 3px
        color: hsl(0deg, 0%, 29%)
        display: flex
        justify-content: center
        margin-bottom: -1px
        padding: 0.5em 1em
        vertical-align: top
        cursor: pointer

    &:hover
        cursor: pointer

    .is-active .tab
        color: $lightBlue
        border-bottom-color: $lightBlue

.image
    &.is-centered
        display: block
        margin-left: auto
        margin-right: auto

    &.is-256x256
        max-width: 256px
        max-height: 256px

.flex-1
    flex: 1

// .button[disabled=true],
// fieldset[disabled=true] .button
//     background-color: hsl(0deg, 0%, 100%)
//     border-color: hsl(0deg, 0%, 86%)
//     box-shadow: none
//     opacity: 0.5

.button[disabled=false],
fieldset[disabled=false] .button
    // background-color: hsl(0deg, 0%, 100%)
    // border-color: hsl(0deg, 0%, 86%)
    // box-shadow: none
    opacity: 1 !important
    cursor: pointer !important

.control
    &.has-icons-left,
    &.has-icons-right
        input:focus ~ .icon.is-left,
        input:focus ~ .icon.is-right
            svg *, svg
                fill: $primary
                color: $primary

.box
    &.is-primary
        box-shadow: none!important
        border:1px solid $primary
        background: color.scale($primary, $lightness: 95%)

    &.is-calculator-grey
        box-shadow: none!important
        border:1px solid #3b3b44
        background: #47464c

    &.is-centered
        width: fit-content
        margin-left: auto
        margin-right: auto

    &.is-left
        width: fit-content
        margin-right: auto

    &.is-right
        width: fit-content
        margin-left: auto

@media (max-width: $tablet)
    .box
        &.is-not-a-box-mobile
            width: 100%
            height: 100%
            padding: 0!important
            background: transparent
            border: none !important

    .button.is-normal-mobile
        font-size: 1rem !important


// Redoing the Bulma menu component:

$menu-item-color: $text !default
$menu-item-radius: $radius-small !default
$menu-item-hover-color: $text-strong !default
$menu-item-hover-background-color: $background !default
$menu-item-active-color: $link-invert !default
$menu-item-active-background-color: $link !default

$menu-list-border-left: 1px solid $border !default
$menu-list-line-height: 1.25 !default
$menu-list-link-padding: 0.5em 0.75em !default
$menu-nested-list-margin: 0.75em !default
$menu-nested-list-padding-left: 0.75em !default

$menu-label-color: $text-light !default
$menu-label-font-size: 0.75em !default
$menu-label-letter-spacing: 0.1em !default
$menu-label-spacing: 1em !default

.menu
  font-size: $size-normal
  // Sizes
  &.is-small
    font-size: $size-small
  &.is-medium
    font-size: $size-medium
  &.is-large
    font-size: $size-large

.menu-list
  line-height: $menu-list-line-height
  a,
  .item
    cursor: pointer
    border-radius: $menu-item-radius
    color: $menu-item-color
    display: block
    padding: $menu-list-link-padding
    &:hover
      background-color: $menu-item-hover-background-color
      color: $menu-item-hover-color
    // Modifiers
    &.is-active
      background-color: $menu-item-active-background-color
      color: $menu-item-active-color
  li
    ul
      +ltr-property("border", $menu-list-border-left, false)
      margin: $menu-nested-list-margin
      +ltr-property("padding", $menu-nested-list-padding-left, false)

.radio
    position: relative

    input[type=radio]
        margin-right: 0.4em

        // FYB Specific styling
        &::after
            content: ''
            width: 15px
            height: 15px
            border-radius: 15px
            position: absolute
            background-color: $light
            border: 1px solid $info

        &:checked::after
            background: $primary
            border-color: $primary

    &.is-medium
        font-size: 1.25rem
        input[type=radio]
            transform: translateY(1px)
            margin-right: 0.6em
            width: 1.25em
            height: 1.25em

            &::after
                width: 1.25em
                height: 1.25em

    &.is-large
        font-size: 1.5rem
        input[type=radio]
            transform: translateY(2px)
            margin-right: 0.8em
            width: 1.6em
            height: 1.6em
            &::after
                width: 1.6em
                height: 1.6em

    &.is-huge
        font-size: 2rem
        input[type=radio]
            transform: translateY(2px)
            margin-right: 0.8em
            width: 1.9em
            height: 1.9em
            &::after
                width: 1.9em
                height: 1.9em

    &.is-block
        display: block
        margin-left: 0 !important
        
        &:not(:last-child)
            margin-bottom: 0.5em

.menu-label
  color: $menu-label-color
  font-size: $menu-label-font-size
  letter-spacing: $menu-label-letter-spacing
  text-transform: uppercase
  &:not(:first-child)
    margin-top: $menu-label-spacing
  &:not(:last-child)
    margin-bottom: $menu-label-spacing

.multi-progress
    display: flex
    justify-content: flex-start
    height: 2.5em
    width: 100%
    border-radius: 8px
    overflow: hidden
    @include shadowLight

    &.is-rounded
        border-radius: 99999px

    &.is-small
        height: 1.8em

    &.is-medium
        height: 3em

    &.is-large
        height: 4em

    &.is-massive
        height: 5em

    .progress-item
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        color: white
        text-align: center
        padding: 0 1em

        &.is-primary
            background: $primary

        &.is-link
            background: $link

        &.is-info
            background: $info

        &.is-white
            background: $light
            color: black

        &.is-black
            background: black
            
.stacked-progress
    min-height: 2.5em
    width: 100%

    &.is-rounded
        border-radius: 99999px

    &.is-small .progress-item
        height: 1.8em

    &.is-medium .progress-item
        height: 3em

    &.is-large .progress-item
        height: 4em

    &.is-massive .progress-item
        height: 5em

    &:not(:hover)
        [data-id=tooltip],
        [data-id=tooltip]::before,
        [data-id=tooltip]::after
            display: none !important
            visibility: hidden !important

    .progress-item
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        color: white
        text-align: center
        padding: 0 1em
        border-radius: 0 8px 8px 0

        &.is-primary
            background: $primary

        &.is-link
            background: $link

        &.is-info
            background: $info

        &.is-white
            background: $light
            color: black

        &.is-black
            background: black

        &:not(:last-child)
            border-bottom-right-radius: 0px !important

        &:first-child
            border-top-left-radius: 8px !important

        &:last-child
            border-bottom-left-radius: 8px !important

@media only screen and (max-width: $tablet)
    .radio
        &.is-huge
            font-size: 1.5rem
    .columns
        margin-left: 0
        margin-right: 0