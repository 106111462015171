@use '~/src/theme' as *
@use 'sass:color'

@keyframes spinInCircles
    from
        transform: rotate(0deg) scale(3)
    to
        transform: rotate(359deg) scale(3)

.loanList
    display: flex
    flex-wrap: wrap
    gap: 1em
    justify-content: center

    .loan
        position: relative
        width: calc(33% - (40px / 2))
        cursor: pointer

        transform-origin: top
        transition: transform 300ms ease
        
        .tile
            display: block
            position: relative

        .swirlyHolder
            display: block
            position: absolute
            border-radius: 4px
            overflow: hidden
            margin-top: 16%
            height: calc(100% - 14.2%)
            width: 100%
            background: white

        .swirly
            position: absolute
            bottom: -42%
            transform: rotate(0deg) scale(3)
            opacity: 0.075
            transition: transform 100ms ease
            animation-iteration-count: infinite
            animation-name: spinInCircles
            animation-duration: 20s
            animation-fill-mode: forwards
            animation-timing-function: linear
            animation-play-state: paused

        &::after
            content: ' '
            box-shadow: 0 3px 6px 3px rgba(0,0,0,0.15)
            position: absolute
            top: 14.2%
            left: 0
            right: 0
            bottom: 0
            width: 100%
            border-radius: 4px
            opacity: 0
            transition: 200ms ease

        &:hover
            z-index: 1
            transform: scale(1.15)

            &::after
                opacity: 1
            
            .swirly
                animation-play-state: running


.PayoffEarlyChallengeBigBox
    display: flex !important
    align-items: center
    justify-content: center
    height: 300px
    cursor: pointer
    transition: background 300ms ease, color 300ms ease

    &:hover
        background: $primary

        :global(.title)
            color: white

// .lineChart canvas
//     position: relative

.miniContainer
    max-width: 450px !important

@media screen and (min-width: $tablet)
    .loanList .loan
        width: calc(25% - (40px / 3))

@media screen and (min-width: $tablet) and (max-width: 1300px)
    .loanList .loan
        width: calc(50% - (40px / 2))

@media screen and (max-width: $tablet)
    .loanList .loan
        width: calc(50% - (40px / 2))

@media screen and (max-width: 500px)
    .loanList .loan
        width: 100%
