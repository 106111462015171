@use '../../../theme.sass' as *

.SVG
    position: relative
    overflow: hidden

    &:hover .fill
        transform: translateY(0) scale(1) !important

    .outline
        position: relative
        z-index: 1
        margin-bottom: -7.2px

        .svgStyle1
            fill: none
            stroke: $info
            stroke-width: 5
            stroke-linecap: round
            stroke-miterlimit: 10
                
        .svgStyle2
            fill: none
            stroke: $info
            stroke-width: 8
            stroke-miterlimit: 10
                
        .svgStyle3
            fill: $info
    
    .fill
        top: 0
        left: 0
        position: absolute
        z-index: 0
        transition: transform 300ms ease
