@use '../theme' as *

.auth
    width: 100vw
    background: #f0f2f5

    .image.is-logo
        max-width: 250px
        display: block
        margin:0 auto

    .is-logincontainer
        width:100%
        max-width: 450px
        margin:0 auto

.authScreen

    &.is-full
        width: 100vw
        height: 100vh
        display: flex
        justify-content: center
        align-items: center
        flex-direction: row

        .art
            height: 100vh
            // width: calc(100vw - 520px)
            width: 100%
            background-size: cover
            background-position: 10% 50%

        .art-text
            position: fixed
            top: 6vh
            left: 6vw

            .title
                font-size: 3vw
                color: white
    
            .subtitle
                font-size: 2vw
                color: white
                opacity: 0.5
        
        .authContainer
            width: 520px
            background: white
            height: 100vh
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            padding-left: 5em
            padding-right: 5em

            .image.is-logo
                max-width: 280px
                margin-top: 8vh

            .authComponent
                width: 100%
                padding-bottom: 15vh

.reset-password
    width: 100%

    .hero,
    .hero-body
        width: 100%

    .container
        max-width: 560px !important

        figure.image
            max-width: 300px
            display: block
            margin-left: auto
            margin-right: auto
            margin-bottom: 2rem

@media (max-width: $fullhd) and (min-width: $desktop)
    .authScreen
        .authContainer
            padding-left: 3em !important
            padding-right: 3em !important
            width: 600px !important

@media (max-width: $desktop) and (min-width: $tablet)
    .authScreen
        .authContainer
            padding-left: 2em !important
            padding-right: 2em !important
            width: 600px !important

        .art-text

            .title
                font-size: 2.5rem !important
    
            .subtitle
                font-size: 1.5rem !important

@media (max-width: $fullhd)
    .authScreen
        .art
            background-position: 15% 50% !important

@media (max-width: $tablet)
    .authScreen
        flex-direction: column !important

        .art,
            // width: 100vw!important
            // height: 35vh !important
            // background-position: 50% 10% !important

        .art-text
            display: none
        
        .authContainer
            width: 100vw !important
            padding: 2em !important
            display: block !important

            .tabs
                width: fit-content
                margin-left: auto
                margin-right: auto

                ul
                    justify-content: center

            .welcomeBack
                display: none

            .image.is-logo
                max-width: 280px
                margin-top: 0 !important
                margin-left: auto
                margin-right: auto

            .authComponent
                width: 100%
                max-width: 400px    
                display: block
                margin-left: auto
                margin-right: auto
                padding-bottom: 200px !important