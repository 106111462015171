@use 'sass:color'

$lightBlue: #049dbf
$darkBlue: #023859
$orange: #f4a23f
$purple: #b352db
$light: #edf7f9
$danger: #dc3545

$primary: $lightBlue
$info: #023859
$link: #2AC6EB
$warning: $orange

$primaryDarker: #276B7B
$warningDarker: #cd8227 // Was #e4c264
$dangerDarker: #b92131
$infoDarker: #01273e

// Screen sizes
$mini: 420px
$tablet: 769px
$desktop: 1024px
$fullhd: 1408px

// Bulma.io
$menu-item-active-background-color: $lightBlue
$menu-item-radius: 15px
$family-sans-serif: 'Nunito', Helvetica, sans-serif
$title-weight: 800
$size-1: 4rem
$size-2: 3rem
$label-weight: 400
$menu-item-hover-background-color: color.scale($primary, $lightness: 85%)
$navbar-item-img-max-height: unset
$navbar-breakpoint: $tablet

@mixin shadowLight
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1)

@mixin shadowMedium
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2)

@mixin shadowDark
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.3)
