@use '../theme' as *

.stacksOfCash

    .image
        &.is-cash
            min-width: 140px
            max-width: 140px
            margin: 0 auto
            cursor: pointer
            opacity: 0.3

            -webkit-user-select: none
            -khtml-user-select: none
            -moz-user-select: none
            -o-user-select: none
            user-select: none

            &.is-active
                opacity: 1

        &.is-cash-activator
            transition: transform 300ms ease

            &:hover
                transform: scale(1.15)

    .hidden-loading-images
        z-index: -9999

    &.has-dragging-items
        .cash-images
            // &:not(.is-drag-target) .image
            //     opacity: 0.4

            // &.is-drag-target .image
            //     opacity: 1

            .is-estimate
                filter: grayscale(1)

    .cash-images
        &.is-disabled
            opacity: 0.2

@media (min-width: 1643px)
    .is-manualStack-button
        display: none !important

@media (max-width: 1642px) and (min-width: $desktop)
    .columns.is-mobile
        flex-wrap: wrap
        justify-content: flex-start

        .column
            flex: 1
            max-width: 25%
            min-width: 25%

            .image.is-cash.is-hidden-touch
                display: none !important

    .cash-images + .title.is-hidden-touch
        display: none

    .myVault
        .column.is-hidden-touch
            display: none

        .column.is-vault-text
            position: fixed
            bottom: 0
            left: 400px
            right: 0
            background: white

            div
                display: flex
                justify-content: center
                align-items: center
                gap: 0.6em


@media (max-width: $fullhd)
    .stacksOfCashTitles
        .title
            font-size: 2.5rem !important

@media (min-width: $tablet) and (max-width: $desktop)
    .columns.is-mobile
        flex-wrap: wrap
        justify-content: flex-start

        .column
            flex: 1
            max-width: 33%
            min-width: 33%

            .image.is-cash.is-hidden-touch
                display: none !important


@media (max-width: $tablet)
    .columns.is-mobile
        flex-wrap: wrap
        justify-content: center

        .column
            flex: 1
            max-width: 50%
            min-width: 50%
            
    .myVault
        .column.is-vault-text
            position: fixed
            bottom: 76px
            left: 0
            right: 0
            background: white

            div
                display: flex
                justify-content: center
                align-items: center
                gap: 0.6em


@media (max-width: $mini)
    .columns.is-mobile
        flex-wrap: wrap
        justify-content: center

        .column
            flex: 1
            max-width: 100%
            min-width: 100%
            

    .columns.is-mobile

        .column
            // flex: 1
            max-width: 100%

    .myVault
        .column.is-vault-text
            bottom: 59px