@use '../../theme' as *
@use 'sass:color'

@keyframes spinInCircles
    from
        transform: rotate(0deg) scale(3)
    to
        transform: rotate(359deg) scale(3)


.envelopes
    display: flex
    gap: 8px
    flex-wrap: wrap

    *
        user-select: none

    .envelope
        display: inline-flex
        // border: 1px solid $darkBlue
        // border-radius: 6px
        width: calc(10% - 8px)
        // height: 62px
        align-items: center
        justify-content: center
        cursor: pointer

        p
            width: fit-content
            font-size: 1.5rem
            font-weight: 600

        .wrapper
            width: 70%
            min-height: 87px
            display: flex
            align-items: flex-end
            justify-content: center

            svg
                width: 100%
                height: 100%

                text
                    font-size: 2.5rem
                    font-weight: 600

            // img
            //     margin-top: 2.65em

        // &.checked
        //      background: $primary

        //     p
        //         color: white

        // &.unchecked
        //     background: color.scale($primary, $lightness: 96%)

        //     p
        //         color: $primary

.DifficultySelector
    :global(.column)
        height: 80vh
        :global(.box)
            display: flex
            align-items: center
            justify-content: center
            flex-flow: column nowrap
            height: 100%
            background: $light
            transition: background 200ms ease, opacity 200ms ease

            *
                transition: transform 300ms ease

            :global(.glow-primary)
                font-weight: bold

            :global(.title),
            :global(.glow-primary)
                color: $primary

            &:hover
                background: $primary
                color: white

                *
                    transform: translateY(-20px)

                :global(.title),
                :global(.subtitle),
                :global(.glow-primary)
                    color: white !important
    &:hover
        :global(.box):not(:hover)
            opacity: 0.6

.ChallengeComplete
    .BucketPreview
        max-width: 235px
        display: block
        margin-left: auto
        margin-right: auto
        text-align: center
        transition: transform 200ms ease

        :global(.bucket-name)
            white-space: nowrap
            font-size: 1.5rem
            font-weight: 800

        &:hover
            transform: scale(1.05)

.WeekSlot
    font-size: 12rem
    line-height: 1

.WeekBox
    padding-top: 3rem
    padding-bottom: 3rem

.GameTile
    position: relative
    cursor: pointer

    transform-origin: top
    transition: transform 300ms ease
    
    .tile
        display: block
        position: relative

    .swirlyHolder
        display: block
        position: absolute
        border-radius: 4px
        overflow: hidden
        margin-top: 19.5%
        height: 87.8%
        width: 100%

    .swirly
        position: absolute
        bottom: 15%
        transform: rotate(0deg) scale(3)
        opacity: 0.035
        transition: transform 100ms ease
        animation-iteration-count: infinite
        animation-name: spinInCircles
        animation-duration: 20s
        animation-fill-mode: forwards
        animation-timing-function: linear
        animation-play-state: paused

    &::after
        content: ' '
        box-shadow: 0 3px 6px 3px rgba(0,0,0,0.15)
        position: absolute
        top: 10.5%
        left: 0
        right: 0
        bottom: 0
        width: 100%
        border-radius: 13px
        opacity: 0
        transition: 200ms ease

    &:hover
        z-index: 1
        transform: scale(1.05)

        &::after
            opacity: 1
        
        .swirly
            animation-play-state: running

.NoneComplete
    margin-top: -3.5em !important

@media screen and (max-width: $tablet)
    .ChallengeComplete
        .BucketPreview
            svg
                *:nth-child(1)
                    fill: $light !important

    .WeekSlot
        font-size: 5rem

    .WeekBox
        padding-top: 1.5rem
        padding-bottom: 2rem
        
    .DifficultySelector
        display: flex
        align-items: flex-start
        justify-content: flex-start
        flex-flow: row wrap
        padding-top: 0
        padding-left: 0.5em
        padding-right: 0.5em

        :global(.column)
            height: calc(50vh - 100px)
            width: 50%
            min-width: 50%
            flex: 1
            padding: 0.35em

            :global(.box)
                background: white

    .envelopes
        justify-content: center

        .envelope
            width: calc(25% - 10px)

@media screen and (max-width: 450px)
    .envelopes
        .envelope
            width: calc(33% - 10px)
