@use '../theme' as *

.Create
    min-height: 100vh
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1000
    display: flex
    background: white

    .main
        padding: 1em 1em 0
        position: relative
        width: 50%

        .wrapper
            display: block
            padding-top: 1em
            max-width: 700px
            margin-left: auto
            margin-right: auto

            .content
                padding-left: 1.5em

    .artwork
        position: relative
        background-size: cover
        background-position: 50%
        width: 50%
        height: 100%

    .progressChunks
        display: flex
        gap: 0.5em

        .progress
            height: 0.75em
            width: 9em
            border-radius: 30px
            background: $light

            &.isActive
                background: $primary

@media screen and (max-width: 769px)
    .Create
        flex-direction: column

        .main
            width: 100%
            max-height: calc(100vh - 10px)
            overflow-y: auto

        .artwork
            display: none
            height: 0
            // height: 200px
            // width: 100%

    .wrapper
        padding-bottom: 30vh