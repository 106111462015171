@use '../../theme' as *
@use 'sass:color'

$miniBucketSize: 80px !default
$selectedColor: #E2F0F4 !default

.paymentHistory

    &.has-focused .payment-history-item:not(.is-active)
        opacity: 0.5

    .payment-history-item
        width: 100% 
        display: block

        &:hover
            cursor: pointer

        &.is-active
            border-color: $primary

        &.type-guess .dropdown-trigger
            border-style: dashed

        &.type-bucket
            .dropdown-trigger
                background: $primary
                &:hover
                    background: color.scale($primary, $lightness: -15%)
                p
                    color: white

        .dropdown-trigger
            display: flex
            flex-flow: row nowrap
            justify-content: flex-start
            align-items: center
            opacity: 1
            background: white
            padding: 0.55em 1.25em
            border-radius: 12px
            border: 1px solid $primary

            &:hover
                background: color.scale($primary, $lightness: 95%)

            p
                flex: 1

                &:last-child
                    text-align: right

        .dropdown-menu
            padding-top: 8px

        .dropdown-content
            background: $primary
            border-radius: 12px
            position: relative

            &::after
                content: " "
                position: absolute
                z-index: 1
                top:-11px
                left: 20%
                width: 25px
                height: 25px
                background: $primary
                transform: rotate(45deg)
                z-index: -1
                pointer-events: none

            .level
                gap: 10px
                
                .level-item
                    width: calc(50% - 5px)

        .dropdown-item
            color: white

        .dropdown-menu
            width: 100%


.payment-history-item:not(:last-child)
    margin-bottom:0.25em
    
@media screen and (max-width: $tablet)
    .paymentHistory
        .payment-history-item
            .level,
            .level .level-item,
            .level .level-item .control,
            .level .level-item .control .field,
            .level .level-item .control .field .input
                width: 100% !important

// List of all buckets
.bucketList
    display: flex !important
    flex-flow: row wrap
    justify-content: center
    gap: 35px

    .bucket
        width: 210px
        transition: transform 300ms ease

        .bucket-render-2
            max-height: 250px

            .fbb-rungs
                display: none!important

            &:hover .fbb-fill
                transform: translateY(0%) scale(1) !important

            .outline .cls-2
                display: none
            
            .outline svg path.cls-1
                fill: white !important

        > .bucket-name
            // margin-top: 0.5em
            text-align: center
            font-weight: 800
            font-size: 1.75rem
            // color: $primary !important

        &:hover
            transform: scale(1.05)

        &.is-link-bucket
            // display: flex
            // align-items: center
            // justify-content: center
            // border: 2px dashed $primary
            // border-radius: 8px
            position: relative
            cursor: pointer

            &:hover
                svg
                    opacity: 1

            svg
                opacity: 0.5
                transition: opacity 200ms ease

            .button
                position: absolute
                // bottom: 5px
                // right: 0
                // left: 0
                // width: fit-content
                // margin-left: auto
                // margin-right: auto
                top: 40%
                left: 50%
                transform: translate(-50%)


.bucketSelect
    max-height: 400px
    overflow-y: auto
    padding: 1em

    .bucketSelectItem
        @include shadowLight
        display: flex
        width: 100%
        align-items: center
        gap: 15px
        border-radius: 8px
        padding: 1em 1.5em 0.5em
        border: 2px solid transparent
        transition: all 300ms ease

        &.is-selected
            background: $selectedColor
            border-color: $primary

            // .fillableBucket .fbb-fill
            //     transform: translateY(0px) scale(1) !important

        &.is-disabled
            pointer-events: none
            opacity: 0.2
            // background: #dfe0e3
            border-color: #dfe0e3 // #373841

            // .fillableBucket .fbb-fill
            //     transform: translateY(0px) scale(1) !important

        &:not(:last-child)
            margin-bottom:1em

        &:not(.is-disabled):hover
            background: $selectedColor
            cursor: pointer

        .bucketSelectText
            margin-bottom: 1em

        .fillableBucket
            max-width: $miniBucketSize
            width: $miniBucketSize

.miniBucketSize
    max-width: $miniBucketSize
    width: $miniBucketSize


@media (max-width: $desktop - 1px) and (min-width: $tablet)
    .bucket-list
        justify-content: center
        align-items: center
        // flex-flow: column
        gap: 20px

        .bucket
            width: calc(33% - 20px)
            transform: none !important

            .bucket-render-4
                .fbb-outline
                    margin-bottom: -8.6px

            .bucket-name
                font-size: 1.2rem
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                
    .bucketView
        .fillableBucket
            max-width: 340px

@media (max-width: $tablet)
    .bucket-list
        justify-content: center
        align-items: flex-start
        // flex-flow: column
        gap: 20px
        background: none !important
        padding: 0 !important

        .bucket
            width: calc(33% - 20px)
            transform: none !important

            .bucket-render-4
                .fbb-outline
                    margin-bottom: -8.6px

                .bucket-cls-0
                    fill: #edf7f9 !important

            .bucket-name
                font-size: 1.2rem
                // white-space: nowrap
                // overflow: hidden
                // text-overflow: ellipsis

    .bucketView
        padding-left: 0 !important
        padding-right: 0 !important
        .fillableBucket
            max-width: 340px

@media (max-width: 500px)
    .bucket-list
        gap: 20px

        .bucket
            width: calc(50% - 20px)
            // width: calc(100% - 20px)

@media (max-width: $mini)
    .bucketView
        .fillableBucket
            width: 75%
            display: block
            margin-left: auto
            margin-right: auto

    .myGoal
        position: fixed
        bottom: 59px
        left: 0
        right: 0
        background: white
        display: flex
        justify-content: center
        align-items: center
        gap: 0.5em
        padding: 0.5em 0


// Bucket :: View one
.bucketView

    .invisible-input
        width: 100%
        font-size: 2.5em
        font-weight: bold
        text-align: center
        max-width: 500px
        display: block
        margin: 0 auto 30px

    .fillableBucket
        // max-width: 577px
        margin-left: auto
        margin-right: auto

        .fbb-bkg
            fill: $light !important

    .completedNumber
        position: absolute
        top: 168px
        bottom: 18px
        left: 0
        right: 0
        text-align: center
        z-index: 10
        pointer-events: none

        .innerCompleted
            position: absolute
            left: 0
            right: 0

            h1
                font-size: 2rem !important

    .rungs
        position: absolute
        bottom: calc(180px - 2.5rem)
        width: calc(100% - 90px)

        &.is-complete
            .bucket-rung:first-child
                border-top: 3px solid #0a3959

        .bucket-rung
            width: 100%
            min-height: 50px
            font-size: 31px
            border-bottom: 3px solid #0a3959
            display: flex
            justify-content: space-between
            align-items: center
            color: #0a3959

// Fillable Bucket Component
.fillableBucket
    position: relative

    &.is-filled
        .bucket-render-2 .fbb-fill polygon,
        .bucket-render-2 .fbb-fill ellipse
            fill: $warning !important

        .bucket-render-3

            .br2d-svg-cls-0,
            .br2d-svg-cls-1,
            .br2d-svg-cls-2,
            .br2d-svg-cls-3
                fill: $warning !important


    .bucket-header
        text-align: center

    .bucket-footer
        text-align: center

    // No longer in use
    .bucket-render-2
        position: relative
        overflow: hidden

        .fbb-component-1
            fill: #00b5d1

        .fbb-component-2
            fill: #fff

        .fbb-component-3
            fill: #059ec0

        .fbb-component-4
            opacity: .3
            stroke-width: 3px

        .fbb-component-4,
        .fbb-component-5
            fill: none
            stroke: #0a3959
            stroke-miterlimit: 10

        .fbb-component-5
            stroke-width: 18px

        .fbb-fill
            transform-origin: center
            transition: transform 0.4s ease

    // No longer in use
    .bucket-render-3
        position: relative
        overflow: hidden
                
        .br2d-svg-cls-0
            fill: #049DBF

        .br2d-svg-cls-1
            opacity: 0.47
            fill: none
            stroke: #BCBCBC
            stroke-width: 2.9235
            stroke-miterlimit: 10

        .br2d-svg-cls-2
            opacity: 0.47
            fill: none
            stroke: #BCBCBC
            stroke-width: 3.0119
            stroke-miterlimit: 10

        .br2d-svg-cls-3
            fill: #2AA7BC

        .br2d-svg-cls-4
            fill: none
            stroke: #0A3959
            stroke-width: 3.0119
            stroke-miterlimit: 10

        .br2d-svg-cls-5
            fill: none
            stroke: #0A3959
            stroke-width: 2.9235
            stroke-miterlimit: 10

        .br2d-svg-cls-6
            fill: none
            stroke: #0A3959
            stroke-width: 2.8075
            stroke-miterlimit: 10

        .br2d-svg-cls-7
            fill: none
            stroke: #0A3959
            stroke-width: 2.3305
            stroke-miterlimit: 10

        .br2d-svg-cls-8
            fill: none
            stroke: #0A3959
            stroke-width: 2.6849
            stroke-miterlimit: 10

        .br2d-svg-cls-9
            fill: none
            stroke: #0A3959
            stroke-width: 2.6102
            stroke-miterlimit: 10

        .br2d-svg-cls-10
            fill: none
            stroke: #0A3959
            stroke-width: 2.5213
            stroke-miterlimit: 10

        .br2d-svg-cls-11
            fill: none
            stroke: #0A3959
            stroke-width: 2.4014
            stroke-miterlimit: 10

        .br2d-svg-cls-12
            fill: none
            stroke: #0A3959
            stroke-width: 10.267
            stroke-miterlimit: 10
            
        .br2d-svg-cls-13
            fill: none
            stroke: #0A3959
            stroke-width: 20.5339
            stroke-miterlimit: 10

    // The new 2d fillable bucket on /bucket/:id page -- Specifically for mobile
    .bucket-render-6
        .br6-fillgroup
            path
                fill: url(#br6-linear-gradient)

        .br-6-cls-0, .br-6-cls-1
            fill: url(#br6-linear-gradient)

        .br-6-cls-2
            fill: #FFFFFF

        .br-6-cls-3
            font-family: 'Arial-BoldMT'

        .br-6-cls-4
            font-size: 42px

        .br-6-cls-5, .br-6-cls-6, .br-6-cls-7, .br-6-cls-8, .br-6-cls-9
            fill: url(#br6-linear-gradient)

        .br-6-cls-10, .br-6-cls-11, .br-6-cls-12, .br-6-cls-13, .br-6-cls-14, .br-6-cls-15
            fill: url(#br6-linear-gradient)

        .br-6-cls-16
            fill: none
            stroke: $info
            stroke-width: 2.53
            stroke-linecap: round
            stroke-miterlimit: 10

        .br-6-cls-17
            fill: none
            stroke: $info
            stroke-width: 17.47
            stroke-linecap: round
            stroke-miterlimit: 10

        .br-6-cls-18
            fill: none
            stroke: $info
            stroke-width: 27.96
            stroke-miterlimit: 10

        .br-6-cls-19
            fill: $info

@media screen and (min-width: $tablet)
    .bigStat
        padding: 1.25rem 0.75rem !important
    
@media screen and (max-width: $tablet)
    // .bucketView
    //     .fillableBucket
    //         margin-top: 20vw

    //         .bucket-render-5
    //             svg
    //                 transform: scale(1.5) translate(25%, -20px)

    //             .cls-8,
    //             .cls-4
    //                 display: none !important

    .bigStat
        padding: 0.95rem 0rem !important

// @media screen and (max-width: $tablet) and (min-width: 540px)
    // .bucketView
    //     .fillableBucket
    //         margin-top: 25vw
    //         .bucket-render-5 svg
    //             transform: scale(1.5) translate(25%, -40px)

.container.for-calculator
    max-width: 700px !important

    .column.is-two-thirds
        .button.is-primary,
        .box
            max-width: 350px


// Mobile sub-menu navigation
.upperMobileNav
    @include shadowLight
    border-radius: 6px
    padding: 0.5em 1em

    position: relative
    width: 100%
    background: white

    display: flex
    align-items: center
    justify-content: space-between

    max-width: 580px
    margin-left:  auto
    margin-right: auto
    margin-bottom: 1em

    .item
        width: 20%

        &.is-active
            color: $primary

        #bucket-for-button
            height: 1.8em
            .bucket-for-button-icon-0,
            .bucket-for-button-icon-1
                stroke: currentcolor !important
            
            .bucket-for-button-icon-2
                fill: currentcolor !important
                
        #calculator_white
            height: 1.8em
            .calculator-white-icon-0
                fill: currentcolor !important
                stroke: currentcolor !important

            .calculator-white-icon-1
                stroke: currentcolor !important