@use '~/src/theme' as *
@use 'sass:color'

.loanList
    display: flex
    flex-wrap: wrap
    gap: 1em
    justify-content: center

    .loan
        width: calc(25% - (40px / 3))
        text-align: center
        border: 1px solid color.scale($primary, $lightness: 50%)
        border-radius: 6px
        padding: 1em 0
        cursor: pointer
        position: relative
        min-height: 116px
        min-width: 300px

        display: flex
        align-items: center
        justify-content: center
        flex-direction: column

        &.completed
            border-color: $warning !important

            &::after
                background: $warning !important

            h1
                color: $warning

        &::after
            content: ' '
            display: block
            position: absolute
            bottom: 0
            left: 0
            right: 0
            height: 3px
            width: 100%
            background: $primary
            border-radius: 6px
            z-index: 0
            transition: height 300ms ease

        h1, h2, p
            position: relative
            z-index: 1
            transition: color 300ms ease

        h1
            font-size: 1.4rem
            font-weight: 800
            color: $primary

        h2
            font-size: 1.1rem
            font-weight: 600
            color: #27272a

        p
            color: #27272a

        &:hover
            color: white
            border-color: $primary
            // background: $primary

            &::after
                height: 100%

            h1, h2, p
                color: white

.PayoffEarlyChallengeBigBox
    display: flex !important
    align-items: center
    justify-content: center
    height: 300px
    cursor: pointer
    transition: background 300ms ease, color 300ms ease

    &:hover
        background: $primary

        :global(.title)
            color: white

// .lineChart canvas
//     position: relative
