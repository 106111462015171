@use '../theme' as *

.CoachPhil
    position: relative
    margin: 0 auto
    width: fit-content

    .message,
    .philBox
        background: $darkBlue
        padding: 0.75em 1em
        *
            color: white

    .message
        border-radius: 12px
        border-bottom-left-radius: 0px !important
        margin-bottom: 0.25em
        margin-left: calc(1em + 50px)

    .phil
        display: flex
        align-items: center
        justify-content: flex-start
        gap: 1em

        figure
            max-width: 50px

        .philBox
            padding: 0.25em 0.75em !important
            font-weight: bold
            border-radius: 5px

            *
                color: 'white'
                font-size: 1.2rem