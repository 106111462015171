@use '../theme' as *

.legal
    width: 100%

    .contact
        padding-bottom: 30vh

    .content
        margin-top: 3em

        h1
            width: fit-content
            border-bottom: 3px solid $primary

    .logoImage
        max-width: 280px
        display: block
        margin-left: auto
        margin-right: auto
