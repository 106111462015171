@use '../../../theme.sass' as *

.bucket-render-5

    .cls-1
        letter-spacing: -.01em

    .cls-2
        stroke-width: 27.96px

    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5
        stroke: $info
        stroke-miterlimit: 10
        cursor: pointer

    .cls-2,
    .cls-3,
    .cls-5
        fill: none

    .cls-3
        stroke-width: 17.47px

    .cls-3,
    .cls-4,
    .cls-5
        stroke-linecap: round

    .cls-4
        stroke-width: 2.52px

    .cls-5
        stroke-width: 2.53px

    .cls-6
        fill: url(#linear-gradient)

    .cls-7,
    .cls-4,
    .cls-8
        fill: $info

    .cls-8
        font-family: Nunito-Regular, Nunito
        font-size: 37.1px

    .cls-9
        fill: url(#linear-gradient-8)

    .cls-10
        fill: url(#linear-gradient-9)

    .cls-11
        fill: url(#linear-gradient-3)

    .cls-12
        fill: url(#linear-gradient-4)

    .cls-13
        fill: url(#linear-gradient-2)

    .cls-14
        fill: url(#linear-gradient-6)

    .cls-15
        fill: url(#linear-gradient-7)

    .cls-16
        fill: url(#linear-gradient-5)

    .cls-17
        fill: url(#linear-gradient-13)

    .cls-18
        fill: url(#linear-gradient-12)

    .cls-19
        fill: url(#linear-gradient-10)

    .cls-20
        fill: url(#linear-gradient-11)

    .cls-21
        letter-spacing: 0em

    .cls-22
        // font-family: Arial-BoldMT, Arial
        fill: #fff
        font-weight: 700
        vertical-align: center
        pointer-events: none

        tspan
            font-size: 38px

            tspan
                font-size: 30px !important

    .cls-23
        letter-spacing: 0em

    .cls-24
        letter-spacing: 0em

    .bucket-invisible-fill
        background: white !important
        fill: white !important
        cursor: pointer

    .bucket-fill
        cursor: pointer

    .bucket-name
        font-family: Nunito-Bold, Nunito
        font-size: 3.5rem
        color: $info
        fill:  $info

    &.step-0
        transform: translatex(24.5%)

        .bucket-fill,
        .bucket-name,
        .is-line,
        .cls-22
            display: none

        #group-1 .cls-22
            display: unset
            color: $primary
            fill: $primary

            tpsan
                color: $primary
                fill: $primary

    &.step-1
        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3,
        #group-2
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3,
        #group-2
            .is-line
                display: none

        #group-2
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-2
        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3,
        // #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3
            .is-line
                display: none

        #group-3
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-3
        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        // #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4
            .is-line
                display: none

        #group-4
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-4
        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        // #group-4,
        #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5
            .is-line
                display: none

        #group-5
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-5
        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        // #group-5,
        #group-4,
        #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6
            .is-line
                display: none

        #group-6
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-6
        #group-10,
        #group-9,
        #group-8,
        #group-7,
        // #group-6,
        #group-5,
        #group-4,
        #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9,
        #group-8,
        #group-7
            .is-line
                display: none

        #group-7
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-7
        #group-10,
        #group-9,
        #group-8,
        // #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9,
        #group-8
            .is-line
                display: none

        #group-8
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-8
        #group-10,
        #group-9,
        // #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10,
        #group-9
            .is-line
                display: none

        #group-9
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-9
        #group-10,
        // #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        #group-10
            .is-line
                display: none

        #group-10
            .is-line
                display: unset
            text
                opacity: 0.4

    &.step-10
        // #group-10,
        #group-9,
        #group-8,
        #group-7,
        #group-6,
        #group-5,
        #group-4,
        #group-3,
        #group-2,
        #group-1
            .bucket-fill,
            .cls-22
                display: none

        // #group-10,
        // #group-9,
        // #group-8,
        // #group-7,
        // #group-6,
        // #group-5,
        // #group-4,
        // #group-3,
        // #group-2,
        // #group-1
        //     .is-line
        //         display: none
